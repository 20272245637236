import { Article } from 'components/Help'
import { buildTranslate } from 'locales'

const t = buildTranslate('points.configuration.help')

type ArticleKeys =
  | 'collect'
  | 'inactivity'
  | 'expiration'
  | 'instructions'
  | 'appearance'
  | 'multipliers'

export const helpArticles: Record<ArticleKeys, Article[]> = {
  collect: [{ title: t('collect.title'), url: t('collect.url') }],
  multipliers: [{ title: t('multipliers.title'), url: t('multipliers.url') }],
  inactivity: [{ title: t('inactivity.title'), url: t('inactivity.url') }],
  expiration: [{ title: t('expiration.title'), url: t('expiration.url') }],
  instructions: [
    { title: t('instructions.title'), url: t('instructions.url') },
  ],
  appearance: [{ title: t('appearance.title'), url: t('appearance.url') }],
}
