import { pointsUrls } from 'utilities/urlUtils'

export default {
  title: 'Configuration',
  page_title: 'Configuration - Points | Thanx',
  collect: {
    title: 'Ways to collect points',
    amount_spent: 'Amount spent',
    when_a_customer: 'When a customer',
    dollar: 'Spends $1',
    they_receive: 'They receive',
    edit: 'Edit',
  },
  multipliers: {
    title: 'Multipliers',
    several_multipliers_rule:
      'If a customer qualifies for multiple multipliers, only the largest multiplier will apply',
    add: 'Add a multiplier',
    empty: 'There are no multipliers currently active',
    edit: 'Edit',
    active: 'ACTIVE',
    scheduled: 'SCHEDULED',
    expired: 'EXPIRED',
    help_title: 'Help',
    list: {
      appearance: 'Appearance to customers',
      what_you_configured: 'What you configured',
      factor: 'Multiplier amount: %{factor}x',
      available: 'Available',
      collect: 'COLLECT %{factor}X',
      remove: 'Remove multiplier',
    },
    date_range: {
      on_any_date: 'on any date',
      on: 'on %{date}',
      from: 'from %{date}',
      until: 'until %{date}',
    },
    delete_confirmation: {
      title: 'Remove this multiplier',
      subtitle:
        'Customers will no longer receive this multiplier in your app and web experience going forward.',
      button: 'Remove multiplier',
    },
  },
  instructions: {
    title: 'Instructions for customers',
    subtitle:
      'Customers will see a section at the bottom of the rewards list that explains what they need to do to collect points. Use the settings below to customize what that section looks like.',
    show_text_title: 'Show text',
    show_image_title: 'Show a custom image',
    edit: 'Edit',
  },
  expiration: {
    title: 'Expiration',
    when_points_expire: 'When points expire',
    inactivity: {
      expire_popover_description_1:
        'A customer’s points will expire if they have not made a qualifying purchase for the number of months configured in this setting.',
      expire_popover_description_2:
        'If a customer’s account is removed or marked as fraudulent, their points will expire immediately.',
      months_after_purchase:
        'months after the last qualifying purchase from the customer',
    },
    fixed_period: {
      expire_popover_description_1:
        'Points expire on the 1st day of the X+1 month in which they are earned.',
      expire_popover_description_2: 'Example',
      expire_popover_description_3:
        'John earns: <br /> 10 points - March 1 <br /> 15 points - March 10 <br /> 20 points - April 8',
      expire_popover_description_4:
        'If the expiration is configured to 6 months, then 10 points will pass the 6 month period after September 1 and 15 points will pass the 6 month period after September 10.',
      expire_popover_description_5:
        'John will still be able to use those 25 points until the end of September. On October 1, those 25 points will expire and be removed from his account. The remaining 20 points will pass the 6 month period on October 8 and will expire and be removed from John’s account on November 1.',
      months_after_purchase:
        'months after they are earned, on the 1st day of the following month',
    },
    edit: 'Edit',
  },
  appearance: {
    title: 'Appearance',
    subtitle:
      'Customers will see this as the name of your points in the app and web. In addition an icon representing a point is also used on select screens.',
    single_point_title: 'What a single point is called',
    multiple_points_title: 'What multiple points are called',
    primary_icon_title: 'Primary icon',
    primary_icon_description:
      'This icon appears in the app and web experience any place a points count is shown. The primary icon is used on white or light backgrounds.',
    secondary_icon_title: 'Secondary icon',
    secondary_icon_description:
      'This icon appears in the app and web experience any place a points count is shown. The secondary icon is used on dark backgrounds.',
    edit: 'Edit',
  },
  help: {
    title: 'Help',
    collect: {
      title: 'How to configure ways to earn points',
      url: `${pointsUrls.configurePoints}#h_3ae21bdfb0`,
    },
    multipliers: {
      title: 'How to configure multipliers',
      url: 'https://help.thanx.com/en/articles/6543084-configuring-your-points-program-from-the-thanx-dashboard#h_477aa0b2df',
    },
    inactivity: {
      title: 'How customer inactivity is determined',
      url: `${pointsUrls.configurePoints}#h_a47bf214ca`,
    },
    expiration: {
      title: 'How to configure points expiration',
      url: `${pointsUrls.configurePoints}#h_a47bf214ca`,
    },
    instructions: {
      title: 'How to configure instructions for customers',
      url: `${pointsUrls.configurePoints}#h_099d392928`,
    },
    appearance: {
      title: 'How to configure the appearance of your points',
      url: `${pointsUrls.configurePoints}#h_b1726db7a3`,
    },
  },
  edit: {
    amount_spent: 'Amount spent',
    when_customer_spends: 'When a customer spends $1 they receive',
    inform_your_customers:
      'Ensure to inform your customers about this change ahead of time. It will also be reflected in your Thanx Terms and Conditions.',
    exit: 'Exit without saving',
    publish: 'Publish changes',
    publish_success: 'Your changes have been published.',
    publish_failed: 'Something went wrong, please try again later.',
    collect: {
      page_title: 'Configure ways to collect - Points | Thanx',
      publish_changes_title: 'Publish changes',
      publish_changes_content: `These changes will affect how customers earn points going forward. They will not affect the number of points your customers currently have.


Ensure to inform your customers about this change ahead of time. It will also be reflected in your Thanx Terms and Conditions.`,
      publish_changes_proceed: 'Publish changes',
      customer_must_spend:
        'Customer must spend %{cost} to collect 1 %{currencyName}',
    },
    expiration: {
      types: {
        inactivity: {
          label: 'Inactivity-based expiration',
          content_part_one: 'Points expire',
          content_part_two:
            'after the last qualifying purchase from the customer',
          terms:
            'If a customer falls into the new inactivity period, their points will expire right away. Make sure the expiration terms are consistent with your state law minimum requirements. Ensure to inform your customers about this change ahead of time. It will also be reflected in your Thanx Terms and Conditions.',
        },
        fixed_period: {
          label: 'Time-based expiration',
          content_part_one: 'Points expire',
          content_part_two:
            'after they are earned, on the 1st day of the following month',
          terms:
            'Points that are older than the new expiration period will expire on the 1st day of the following month. Make sure the expiration terms are consistent with your state law minimum requirements. Ensure to inform your customers about this change ahead of time. It will also be reflected in your Thanx Terms and Conditions.',
        },
      },
      page_title: 'Expiration',
      unit: 'months',
      publish_changes_content:
        'These changes will apply immediately. If a customer falls into the new inactivity period, their points will expire right away.',
    },
    instructions: {
      page_title: 'Configure instructions for customers - Points | Thanx',
      show_text: 'Show text',
      text_label: 'Description of how customers collect points',
      show_image: 'Show a custom image',
      preview: 'Preview',
      change: 'Change',
      badge: {
        app: 'app',
        web: 'web',
        width: '1125px wide',
        jpg: 'jpg',
        png: 'png',
      },
      publish_modal: {
        title: 'Publish changes',
        message:
          'Customers will see these changes immediately in your app and web experience. You can’t undo these changes.',
        proceed: 'Publish changes',
      },
    },
    appearance: {
      page_title: 'Configure appearance - Points | Thanx',
      title: 'Appearance of points',
      subtitle:
        'Customers will see this as the name of your points in the app and web. In addition an icon representing a point is also used on select screens.',
      name: {
        title: 'What points are called',
        subtitle: 'This name will always be capitalized.',
        singular_label: 'What a single point is called',
        plural_label: 'What multiple points are called',
      },
      icon: {
        title: 'How points looks',
        primary: {
          title: 'Primary icon',
          subtitle: 'Appears on white backgrounds',
        },
        secondary: {
          title: 'Secondary icon ',
          subtitle: 'Appears on dark backgrounds',
        },
        badge: {
          app: 'app',
          web: 'web',
          dimensions: '192px x 192px',
          jpg: 'jpg',
          png: 'png',
          gif: 'gif',
        },
      },
      publish_modal: {
        title: 'Publish changes',
        message:
          'Customers will see these changes immediately in your app and web experience. You can’t undo these changes.',
        proceed: 'Publish changes',
      },
    },
  },
}
