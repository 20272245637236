import { alert } from 'actions/flash'
import { getUploadUrl } from 'actions/s3'
import axios from 'axios'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isUndefined from 'lodash/isUndefined'
import React, { useEffect, useRef } from 'react'
import UnlayerEditor, {
  Editor as UnlayerObject,
  EmailEditorProps,
} from 'react-email-editor'
import { I18n } from 'react-redux-i18n'
import store from 'store'
import { deepOmitBy } from 'utilities/objectUtils'

const t = buildTranslate('thanx_campaigns.errors.email_builder')

type Props = {
  className?: string
  projectId?: number
  style?: React.CSSProperties
  options: Partial<EmailEditorProps['options']>
} & PassDownProps<any | null>

function UnlayerWithFormsy(props: Props) {
  const loadTimer = useRef<NodeJS.Timeout | null>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    loadTimer.current = setTimeout(() => {
      store.dispatch(
        alert({
          key: 'danger',
          message: I18n.t('thanx_campaigns.errors.email_builder.load_error'),
        })
      )
    }, 10000)

    return () => {
      if (loadTimer.current) {
        clearTimeout(loadTimer.current)
      }
    }
  }, [])

  function handleDesignUpdated(unlayer: UnlayerObject) {
    unlayer.exportHtml(data => {
      const cleanedDesign = deepOmitBy(data.design, isUndefined)
      props.setValue(cleanedDesign)
    })
  }

  function handleDesignLoaded(unlayer: UnlayerObject) {
    if (loadTimer.current) {
      clearTimeout(loadTimer.current)
    }

    handleDesignUpdated(unlayer)
  }

  // See https://docs.unlayer.com/docs/custom-file-storage
  async function handleImage(
    files: { attachments: Array<File> },
    done: (data: { progress: number; url?: string }) => void
  ) {
    done({ progress: 1 })

    const file = files.attachments[0]

    const uploadResponse = await dispatch(
      getUploadUrl({
        filename: file.name,
        type: 'image',
        acl: 'public-read',
      })
    )

    if (uploadResponse.error) {
      const error = uploadResponse.error.response
        ? uploadResponse.error.response.data.error
        : null
      dispatch(
        alert({
          key: 'danger',
          message: t('image_upload_error'),
          error: error,
          displayDetails: false,
        })
      )
      return
    }

    done({ progress: 25 })

    const url = uploadResponse.payload.data.upload_url.url
    const options = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: event => {
        const percent = (event.loaded / event.total) * 100
        const progress = 25 + percent * 0.75
        if (progress < 100) {
          done({ progress })
        }
      },
    }

    let response
    try {
      response = await axios.put(url, file, options)
    } catch (error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('image_upload_error'),
          displayDetails: false,
        })
      )
      return
    }

    const imageUrl = response.config.url
    done({ progress: 100, url: imageUrl.split('?')[0] })
  }

  function handleLoad(unlayer: UnlayerObject) {
    unlayer.removeEventListener('design:updated')
    unlayer.addEventListener('design:updated', () =>
      handleDesignUpdated(unlayer)
    )

    unlayer.removeEventListener('design:loaded')
    unlayer.addEventListener('design:loaded', () => handleDesignLoaded(unlayer))

    unlayer.unregisterCallback('image')
    unlayer.registerCallback('image', handleImage)

    if (props.value) {
      unlayer.loadDesign(props.value)
    }
  }

  return (
    <div className={`unlayer-wrapper absolute ${props.className}`}>
      <UnlayerEditor
        onLoad={handleLoad}
        onReady={() => loadTimer.current && clearTimeout(loadTimer.current)}
        options={{
          ...props.options,
          projectId: props.projectId,
        }}
        style={props.style}
      />
    </div>
  )
}

export default withFormsy(UnlayerWithFormsy)
